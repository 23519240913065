import React from 'react'
import CryptoProjects from '../components/CryptoProjects';

const CryptoProjectPages = () => {
  return (
    <>
        <CryptoProjects/>
    </>
  )
}

export default CryptoProjectPages;

