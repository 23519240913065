import React from "react";
import Icon1 from "../../images/CryptoProjectImages/Playfair.png";
import Icon2 from "../../images/CryptoProjectImages/Caesar.png";
import Icon3 from "../../images/MLProjectImages/wine.png";
import { BrowserRouter as Router, Link } from "react-router-dom";

import {
  ProjectsContainer,
  ProjectsWrapper,
  ProjectsCard,
  ProjectsIcon,
  ProjectsP,
  ProjectsH2,
  ProjectsH1,
  Icon
} from "./CryptoProjectsElements";

const CryptoProjects = () => {
  return (
    <ProjectsContainer id="projects">
      <Icon to="/">BRIANOH</Icon>
      <ProjectsH1>Cryptography Projects</ProjectsH1>
      <ProjectsWrapper>
        <ProjectsCard>
          <a
            href="https://github.com/Bdo1112/Playfair-decryption.git"
            target="_blank"
          >
            <ProjectsIcon src={Icon1} />
          </a>
          <ProjectsH2>Playfair Cipher decrypter</ProjectsH2>
          <ProjectsP>
            Used mathmatical and statistical approach to solve Playfair using Python and NumPy.
            {/* Trained the model with Support Vector Machine, Random Forest
            Classifier, Multilayer Precentro algorithms with 8-1-1 and 6-2-2
            dataset ratio. */}
          </ProjectsP>
        </ProjectsCard>

        {/* <ProjectsCard>
          <a
            href="https://github.com/Bdo1112/Wholesale-dataset.git"
            target="_blank"
          >
            <ProjectsIcon src={Icon2} />
          </a>
          <ProjectsH2>Caesar Cipher decrypter</ProjectsH2>
          <ProjectsP>
            Brute force approach to solve Playfair using Python and NumPy.
          </ProjectsP>
        </ProjectsCard> */}

      </ProjectsWrapper>
    </ProjectsContainer>
  );
};

export default CryptoProjects;
