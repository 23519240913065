import React from 'react'
import ResumeLong from '../../Resume/Resume_Long_Final.pdf'

const Resume = () => {
  return (
    <>
        <iframe src={`${ResumeLong}#view=fitH`} title="ResumeLong" height="1500px" width="100%" />
    </>
  )
}

export default Resume;