import React from 'react'
import Resume from '../components/Resume'

const ResumePage = () => {
  return (
    <>
        <Resume/>
    </>
  )
}

export default ResumePage