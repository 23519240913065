import React from "react";
import Icon1 from "../../images/Project-1-tomodoro.png";
import Icon2 from "../../images/Project-2-ML.png";
import Icon3 from "../../images/Project-3-cryptography.png";
import { BrowserRouter as Router, Link } from "react-router-dom";

import {
  ProjectsContainer,
  ProjectsWrapper,
  ProjectsCard,
  ProjectsIcon,
  ProjectsP,
  ProjectsH2,
  ProjectsH1,
} from "./ProjectsElements";

const Projects = () => {
  return (
    <ProjectsContainer id="projects">
      <ProjectsH1>Projects</ProjectsH1>
      <ProjectsWrapper>
        <ProjectsCard>
          <a href="https://pomodoro.thebdo.tech" target="_blank">
            <ProjectsIcon src={Icon1} />
          </a>
          <ProjectsH2>Pomodoro Timer</ProjectsH2>
          <ProjectsP>Work not hard but smart!</ProjectsP>
        </ProjectsCard>
        <ProjectsCard>
          <Link to="/mlprojects">
            <ProjectsIcon src={Icon2} to={"/mlprojects"} />
          </Link>
          <ProjectsH2>Machine Learning Project</ProjectsH2>
          <ProjectsP>based on different dataset</ProjectsP>
        </ProjectsCard>
        <ProjectsCard>
          <Link to="/cryptoprojects">
          <ProjectsIcon src={Icon3} />
          </Link>
          <ProjectsH2>CryptoSolvers</ProjectsH2>
          <ProjectsP>
            {" "}
            <a
              href="https://en.wikipedia.org/wiki/Playfair_cipher"
              target="_blank"
            >
              {" "}
              Playfair Cipher{""}
            </a>
          </ProjectsP>
        </ProjectsCard>
      </ProjectsWrapper>
    </ProjectsContainer>
  );
};

export default Projects;
