import homeObjOneImg from "../../images/ObjOneImg.jpg";
import homeObjTwoImg from "../../Resume/Resume_Short_Final.png";

export const homeObjOne = {
  id: "about",
  to: "/contact",
  lightBg: true,
  lightText: false,
  lightTextDesc: true,
  topLine: "Hi, Nice to meet you!",
  headline: "I am Brian Oh",
  description:
    "Recently graduated with Computer Science Degree from York University and previously worked in finance industry. Interested in finding patterns and learning meaningful knowledge from data to provide optimized solutions to enhance the quality of life for all.",
  buttonLabel: "Let's talk!",
  imgStart: true,
  img: homeObjOneImg,
  alt: "About",
  href: "http://www.thebdo.tech",
  dark: true,
  primary: true,
  darkText: true,
  link: true,
};

export const homeObjTwo = {
  id: "resume",
  to: "/resume",
  lightBg: true,
  lightText: false,
  lightTextDesc: true,
  topLine: "Resume",
  headline: "Experience Matters",
  description:
    "Previously worked in Finance teams at Indiva (CVE: NDVA) and Fanshawe College. Now, I am starting a new journey as a developer.",
  buttonLabel: "Full Ver. Resume (Last Updated: Jul 2022)",
  imgStart: false,
  img: homeObjTwoImg,
  alt: "resume",
  href: "https://drive.google.com/file/d/1oVkw4kc9mX8Qo4JQw4XqTMxPnsEZw2Kq/view?usp=sharing",
  dark: true,
  primary: true,
  darkText: true,
  link: true,
};

// export const homeObjTwo = {
//     id: 'about',
//     lightBg: true,
//     lightText: true,
//     lightTextDesc: true,
//     topLine: 'About Brian',
//     headline: 'Unlimited',
//     description: "GEATEEE",
//     buttonLabel: 'Get Started',
//     imgStart: false,
//     dark: true,
//     primary: true,
//     darkText: false,
//     href: "/"
// };

// export const homeObjThree = {
//     id: 'about3',
//     lightBg: false,
//     lightText: true,
//     lightTextDesc: true,
//     topLine: 'About Brian',
//     headline: 'Unlimited',
//     description: "GEATEEE",
//     buttonLabel: 'Get Started',
//     imgStart: false,
//     dark: true,
//     primary: true,
//     darkText: false,
//     href: "/"
// };
