import React from 'react'
import Contact from '../components/Contact';

const ContactPages = () => {
  return (
    <>
      <Contact />
    </>
  )
}

export default ContactPages