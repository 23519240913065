import React from "react";
import Icon1 from "../../images/MLProjectImages/penguin.png";
import Icon2 from "../../images/MLProjectImages/wholesale.png";
import Icon3 from "../../images/MLProjectImages/wine.png";
import { BrowserRouter as Router, Link } from "react-router-dom";

import {
  ProjectsContainer,
  ProjectsWrapper,
  ProjectsCard,
  ProjectsIcon,
  ProjectsP,
  ProjectsH2,
  ProjectsH1,
  Icon
} from "./MLProjectsElements";

const MLProjects = () => {
  return (
    <ProjectsContainer id="projects">
      <Icon to="/">BRIANOH</Icon>
      <ProjectsH1>Machine Learning Projects</ProjectsH1>
      <ProjectsWrapper>
        <ProjectsCard>
          <a
            href="https://github.com/Bdo1112/penguin-dataset.git"
            target="_blank"
          >
            <ProjectsIcon src={Icon1} />
          </a>
          <ProjectsH2>Penguin Dataset</ProjectsH2>
          <ProjectsP>
            Trained the model with Support Vector Machine, Random Forest
            Classifier, Multilayer Precentro algorithms with 8-1-1 and 6-2-2
            dataset ratio.
            {/* Trained the model with Support Vector Machine, Random Forest
            Classifier, Multilayer Precentro algorithms with 8-1-1 and 6-2-2
            dataset ratio. */}
          </ProjectsP>
        </ProjectsCard>
        <ProjectsCard>
          <a
            href="https://github.com/Bdo1112/Wholesale-dataset.git"
            target="_blank"
          >
            <ProjectsIcon src={Icon2} />
          </a>
          <ProjectsH2>Wholesale Dataset</ProjectsH2>
          <ProjectsP>
            {/* To identify different customer groups by using various optimalization techniques. #RFECV, #KMeans_Clustering, #PCA */}
            {/* Performed optimalization to determine the best features with various
            ML techniques and trained the model with XGBoost Classifier. */}
            Determined optimal number of features using RFECV, optimal number of
            clusters in KMeans Clustring based on elbow method, Implement PCA to
            determine variances between different number of components.
          </ProjectsP>
        </ProjectsCard>
        <ProjectsCard>
          <a
            href="https://github.com/Bdo1112/Wine-quality-dataset.git"
            target="_blank"
          >
            <ProjectsIcon src={Icon3} />
          </a>
          <ProjectsH2>Wine Quality Dataset</ProjectsH2>
          <ProjectsP>
            Trained the model with Decision Tree Regressor, Linear Regressor,
            and Random Froest Regressor with TensorFlow and Keras. Make a
            business decisions based on the result.
          </ProjectsP>
        </ProjectsCard>
      </ProjectsWrapper>
    </ProjectsContainer>
  );
};

export default MLProjects;
