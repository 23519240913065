import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages';
import ContactPage from './pages/Contact'
import ResumePage from './pages/Resume'
import MLProjectsPage from './pages/MLProject'
import CryptoProjectsPage from './pages/CryptoProject'


function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/contact" element={<ContactPage/>} />
      <Route path="/resume" element={<ResumePage/>} />
      <Route path="/mlprojects" element={<MLProjectsPage/>} />
      <Route path="/cryptoprojects" element={<CryptoProjectsPage/>} />
    </Routes>
  </Router>
  );
}

export default App;
