import React, { useState } from "react";
// import { FaArrowRight } from "react-icons/fa";
import Video from "../../videos/video.mp4";
// import { Button } from "../ButtonElements";
import TypeWriter from "typewriter-effect";

import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContains,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
} from "./HeroElements";


const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer id="home">
      <HeroBg>
        <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
      </HeroBg>
      <HeroContains>
        <HeroH1>
          {/* <TypeWriter
          onInit={(typewriter) => {
            typewriter
              .typeString("Hello")
              .pauseFor(2000)
              .deleteAll()
              .typeString("Hi")
              .pauseFor(2000)
              .deleteAll()
              .start();
          }}
        /> */}
          <TypeWriter
            options={{
              strings: ["Hi, Nice to meet you!", "안녕하세요, 만나서 반가워요!", "很高兴认识你!", "Mucho gusto!", "Tôi rất vui được gặp bạn!"],
              autoStart: true,
              loop: true,

            }}
          />
          <TypeWriter
            options={{
              strings: ["I am Brian Oh.", "오동현 입니다.", "我叫吴东现.", "Me llamo Brian Oh.", "Tôi là Brian Oh."],
              pauseFor: "1500",
              autoStart: true,
              loop: true,
            }}
          />
          
          {/* <TypeWriter
          onInit={(typewriter) => {
            typewriter
              .typeString("Hi, Nice to meet you!")
              .pauseFor(5000)
              .deleteAll()
              .typeString("안녕!만나서 반가워요!")
              .pauseFor(3000)
              .deleteAll()
              .start();
          }}
        />
          <TypeWriter
          onInit={(typewriter) => {
            typewriter
              .pauseFor(3000)
              .typeString("I am Brian Oh.")
              .pauseFor(3000)
              .deleteAll()
              .pauseFor(3000)
              .typeString("제이름은 오동현 입니다.")
              .deleteAll()
              .start();
          }}
        /> */}

          {/* <TypeWriter
            options={{
              strings: ["I am Brian Oh", "오동현 입니다"],
              autoStart: true,
              loop: true,
            }}
          /> */}
        </HeroH1>
        {/* <HeroP>Body text</HeroP> */}
        {/* Why my language is not here? Please contact me! I am more than happy to add more languages!  */}
        <HeroBtnWrapper>
          {/* <Button to="sißgnup" onMouseEnter={onHover} onMouseLeave={onHover}>
            Get started {hover ? <ArrowForward /> : <FaArrowRight />}
          </Button> */}
        </HeroBtnWrapper>
      </HeroContains>
    </HeroContainer>
  );
};

export default HeroSection;
