import React from "react";
import { Button } from "../ButtonElements";
import { animateScroll as scroll } from "react-scroll";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  Column2,
  ImgWrap,
  Img,
  TextWrapper,
} from "./InfoElements";

{
  /* <link rel=¨stylesheet¨ type=¨text/css¨ href=¨LinkElements.css¨> */
}

const InfoSection = ({
  lightBg,
  id,
  to,
  imgStart,
  topLine,
  lightText,
  headline,
  darkText,
  description,
  description2,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
  href,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <Subtitle darkText={darkText}>{description2}</Subtitle>
                <BtnWrap>
                  {/* <a href={href} class = "button" > */}
                  <Button
                    to={to}
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    // offset={-120}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                    style={{ textDecoration: "none" }}
                  >
                    {buttonLabel}
                  </Button>
                  {/* </a> */}
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <a href={href} target="_blank">
                <ImgWrap>
                  <Img id={id} src={img} alt={alt} />
                </ImgWrap>
              </a>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
