import React from 'react'
import MLProjects from '../components/MLProjects'

const MLProjectPage = () => {
  return (
    <>
        <MLProjects/>
    </>
  )
}

export default MLProjectPage